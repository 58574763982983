.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root, .ant-layout {
  height: 100%;
}

.img-logo {
  padding: 15px 15px 15px 15px;
}

header,.ant-menu, .ant-layout-sider-children, .img-logo {
  background-color: #062656 !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #0ab4aa !important;
}

.ant-layout-sider-children {
  position: relative;
}

.img-logo-bmce {
  position: absolute;
  bottom: 0;
}

.search-input {
  margin-bottom: 10px !important;
  margin-right: 10px !important;
  width: 260px;
}

.date-from {
  margin-right: 10px !important;
}

.new-button {
  height: 54px;
  background-color: #00539C !important;
}

.new-button-div {
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 10px;
}

.log-out-button {
  background-color: #062656 !important;
  border-color: #00539C !important;
}

.MuiTableRow-root {
  background-color: rgba(0,0,0,.05);
}


thead {
  background: #343a40;
}

.table-1 thead {
  background-color: white;
}

.css-1ygcj2i-MuiTableCell-root {
  color: white !important;
}

button {
  background-color: #00539C;
}

.boutons-en-bas, .boutons-en-bas-2 {
  margin-right: 10px !important;
}

.boutons-en-bas-2 {
  background-color: #6c757d!important
}

tbody tr:hover {
  background-color: #FFFAF0;
}

.center-buttons {
  padding-bottom: 5px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.title-top {
  margin-top: 10px;
}

.login-page {
  height: 100%;
  background-color: #00294d;
}

.login-box {
  margin: 0 auto;
  width: 400px;
  padding-top: 200px;
}

.login-logo {
  background-color: #00294d;
  margin-bottom: 40px;
  width: 100%;
}

.login-title {
  text-align: center;
  /*border: 1px solid #fff;*/
  margin-bottom: 10px;
}

.login-user {
  background-color: #00294d !important;
  margin-bottom: 10px !important;
  color: white !important;
  width: 100%;
}

.sign-up {
  color: white;
  text-align: center;
}

.signup-box {
  margin: 0 auto;
  width: 800px;
  padding-top: 200px;
  text-align: center;
}

.signup-logo {
  background-color: #00294d;
  margin-bottom: 10px;
  width: 50%;
}

.signup-title {
  color: #fff
}

.signup-user, .invalid-input {
  background-color: #00294d !important;
  margin-bottom: 10px !important;
  color: white !important;
  width: 50% !important;
}

.MuiTablePagination-toolbar .MuiInputBase-root {
  margin-bottom: 10px !important;
}

.MuiTableSortLabel-root {
  color: white !important;
}

.css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
  color: white !important;
}

.MuiTableSortLabel-icon:hover {
  color: white !important;
}


.status-btn-0, .status-btn-1, .status-btn-2, .status-btn-3, .status-btn-4, .status-btn-5 {
  color: #fff;
  padding: 10px;
  cursor: pointer;
  display: inline-block;
  width: -webkit-fill-available;
  text-align: center;
  border-radius: 100px;
}

.status-btn-0 {
  background-color: #A52A2A;
  border-color: #A52A2A;
  width: 170px;
}

.status-btn-1 {
  background-color: #D2691E;
  border-color: #D2691E;
  width: 170px;
}

.status-btn-3 {
  background-color: #6495ED;
  border-color: #6495ED;
  width: 170px;
}

.status-btn-2 {
  background-color: #9400D3;
  border-color: #9400D3;
  width: 170px;
}

.status-btn-4 {
  background-color: #28a745;
  border-color: #28a745;
  width: 170px;
}

.status-btn-5 {
  background-color: #FF8C00;
  border-color: #FF8C00;
  width: 170px;
}

.upload-bc {
  /*margin-left: 10px;*/
  margin-top: 8px;
  background: #00008B !important;
  border-color: #00008B !important;

}

.bc-text {
  font-weight: bold;
  margin-left: 10px;
}

.upload-files {
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.title-account {
  font-weight: bold;
}

.account-text {
  font-size: 20px;
}

.ant-layout-sider {
  flex: 0 0 215px !important;
  max-width: 215px !important;
  width: 215px !important;
}

.table-management .MuiTableCell-root .MuiButtonBase-root {
  padding: 12px !important;
}

.user-top-div {
  display: flex;
}

.user-title {
  width: 500px;
}

.user-plus {
  width: -webkit-fill-available;
  margin-left: auto;
  margin-right: 0;
}

.anticon-plus {
  float: right;
  margin-top: 10px;
  font-size: 20px !important;
}

.user-form-button {
  margin-top: 15px !important;
  float: right;
}

.user-form{
  display: flex;
}

.user-form > .css-1duy1hk .MuiTextField-root {
  width: 25ch !important;
}

/* Pour la page onboarding provider
.ant-select-arrow {
    color: white !important;
}

.ant-select-selector {
    background-color: #00294d !important;
}
*/

.table-history thead {
  background: unset !important;
}

.main-table tr td {
  padding: 12px;
}

/*.main-table tr:nth-child(even) td {
  padding: 0px !important;
}*/

.table-history tr td {
  /*padding: 0px !important;*/
}

.invalid-input {
  border-color: red !important;
}

.splide {
  padding: 0px !important
}

@media only screen and (max-width: 600px) {
  .css-1ygcj2i-MuiTableCell-root {
    padding: 0 !important;
  }
  .img-logo-bmce, .invoice-title {
    display: none;
  }

  .ant-layout-sider {
    max-width: 80px !important;
  }
  .text-account {
    width: 100% !important;
  }
}

.invoice-title {
  font-size: 34px;
  font-family: auto;
}

.du-dash {
  margin-bottom: 10px;
  margin-right: 10px;
}

@media only screen and (min-width: 600px) {
  .champs-search {
    display: flex;
  }
}

.text-account {
  width: 40ch;
}

.main-table tr {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgb(224, 224, 224);
}

.main-table td {
  border-bottom: none;
}

.table-checklist td{
  padding: 12px;
  font-size: 20px;
}

.upload-btn {
  padding: 0 !important;
  display: flex;
}

.bc-title {
  font-size: 16px;
  margin-right: 10px;
  margin-top: 14px;
}

.delete-icon {
  margin-top: 14px;
}

.delete-icon:hover {
  cursor: pointer;
}

.info-box-icon-1, .info-box-icon-2, .info-box-icon-3, .info-box-icon-4 {
  width: 100px;
  height: 100px;
  color: white;
  /*padding: 10px*/
}

.info-box-icon-1 {
  /*background-color: #329ABD !important;*/
}

.info-box-icon-2 {
  /*background-color: #636577 !important;*/
}

.info-box-icon-3 {
  /*background-color: #7AB6B7 !important;*/
}

.info-box-icon-4 {
  /*background-color: #124567 !important;*/
}

.col-indicateurs {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  display: flex !important;
}

.titles-indic {
  margin-left: 5px;
  margin-top: -3px;
  /*background: white;*/
  width: 50%;
}

.row-indicateurs {
  margin-top: 14px;
  margin-bottom: 30px;
}

.MuiTableCell-head {
  padding-bottom: 16px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 16px !important;
}

.ant-menu-title-content {
  display: flex;
}

.upload-rib {
  margin-bottom: 10px;
}

.rib-div {
  /*width: 50% !important;*/
  /*text-align: left;*/
  color: white;
  margin-bottom: 10px;
  margin-left: 20px;
}

.mandatory {
  color: red
}